<template>
  <div>
    <div class="user-table table">
      <TableLoader v-if="loader" :fields="fields" />
      <b-row v-else>
        <b-col cols="12">
          <!-- data table start from here -->
          <b-table id="table" responsive :items="items" :fields="fields" show-empty
            class="scrollable-element user-table-item time-sheet-table tableDesigns">
            <template #cell(full_name)="itemData">
              <div class="media align-items-center">
                <div class="media-aside align-items-center">
                  <b-avatar size="md" :src="itemData.item.picture" :text="itemData.item.full_name | avatarText" badge
                    :badge-variant="userStatus(itemData.item.clock)" :variant="randomVariant(1, items.length)"
                    badge-offset="-2px" class="badgeDesign" />
                </div>

                <div class="media-body">
                  <p class="d-block user-emp-name tabletxtColor" :style="{ color: '#' + itemData.item.color }">
                    {{ itemData.item.name }} {{ itemData.item.last_name }}
                  </p>
                </div>
              </div>
            </template>
            <template #cell(duration)="itemData">
              <span v-for="n in 6" :key="n" :style="n == 1
                  ? 'width: 16.40%;'
                  : n == 6
                    ? 'width: 0%;'
                    : 'width: 16.40%;'
                " class="progressbar-fix-parameter">
                {{ getTimeLabel(n) }}
              </span>

              <!--when user task have length-->
              <b-progress v-if="itemData.item.clock && itemData.item.clock.length" :max="max" class="float-left w-100">
                <b-progress-bar v-for="(p, index) in itemData.item.clock" :key="index" v-b-tooltip.hover.top.html="prepareToolTipValue(p)
                  " class="custom-progress" :style="{
                    'background-color':
                      '#' +
                      (p && p.project && p.project.color
                        ? p.project.color
                        : '6bbe74'),
                  }" :value="(p.time_diff / 86400) * 100" show-progress />
              </b-progress>
              <!--when user task have no length-->
              <b-progress v-else :max="max" class="float-left w-100">
                <b-progress-bar v-b-tooltip.hover.top="'Not worked'" value="100"
                  :style="{ 'background-color': '#d3d3d3' }" />
              </b-progress>

            </template>
            <template #cell(time)="itemData">
              <p class="tabletxtColor">
                {{ totalDailyTime(itemData.item.clock) }}
              </p>
            </template>
          </b-table>
          <!-- data table end -->

          <!-- if not found any record -->
          <img v-if="totalCount == 0 && !loader" src="@/assets/images/nodatafound/nodata.svg" alt="no-data"
            class="no-data-img">
        </b-col>
        <!-- pagination section -->
        <b-col cols="12">
          <Pagination v-if="totalCount > 0" :current-page="currentPage" :per-page="perPage" :total-count="totalCount"
            @currentPage="currentPage = $event" />
        </b-col>
        <!-- pagination section complete here -->
      </b-row>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import * as moment from 'moment/moment'
import TableLoader from '@/components/loaders/table-loading.vue'
import Pagination from '../../components/pagination/index.vue'

export default {
  name: 'ViewEditbody',
  components: {
    TableLoader,
    Pagination,
  },
  props: {
    startDate: {
      type: String,
      required: true,
      default: null,
    },
    endDate: {
      type: String,
      required: true,
      default: null,
    },
    userID: {
      type: Array,
      required: false,
      default: null,
    },
    manageBy: {
      type: String,
      required: false,
      default: null,
    },
    reportingTo: {
      type: String,
      required: false,
      default: null,
    },
    perPage: {
      type: [Number, String],
      required: false,
      default: null,
    },
    groupId: {
      type: String,
      required: false,
      default: null,
    },
    isActive: {
      type: String,
      required: false,
      default: 'active',
    },
  },
  data() {
    return {
      loader: false,
      data: [],
      max: 100,
      fields: [
        {
          key: 'full_name',
          label: 'Name',
          sortable: true,
          thStyle: { width: '274px' },
          tdStyle: { width: '274px' },
        },
        {
          key: 'duration',
          label: 'duration',
          tdStyle: { width: '274px' },
        },
        {
          key: 'time',
          label: ' time',
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          tdStyle: { width: '100px' },
        },
      ],
      items: [],
      logData: [],
      totalCount: 0,
      currentPage: 1,
    }
  },
  computed: {},
  watch: {
    perPage() {
      if (this.currentPage === 1) {
        this.timeSheetData()
      } else {
        this.currentPage = 1
      }
    },
    startDate(nv) {
      if (nv) {
        this.timeSheetData()
      }
    },
    userID() {
      this.timeSheetData()
    },
    isActive() {
      this.timeSheetData()
    },
    currentPage: {
      handler(value) {
        if (value) {
          this.timeSheetData()
          this.$route.query.page_number = value
        }
      },
    },
  },
  mounted() {
    if (this.startDate && this.endDate) {
      this.timeSheetData()
    }
    eventBus.$on('loadTaskList', data => {
      if (data) {
        this.eventBusCommonLogic(data)
      }
    })
    eventBus.$on('logListReLoad', data => {
      if (data) {
        this.eventBusCommonLogic(data)
      }
    })
    eventBus.$on('clockDailyTimeSheet', data => {
      if (data) {
        this.eventBusCommonLogic(data)
      }
    })
  },
  methods: {
    eventBusCommonLogic(data) {
      if (data) {
        /* to avoid multiple api call */
        if (this.currentPage === 1) {
          this.timeSheetData()
        } else {
          this.currentPage = 1
        }
      }
    },
    totalDailyTime(data) {
      let total = 0

      data.forEach(entry => {
        if (entry && entry.in_time && entry.out_time && (!entry.project || entry.project.color === 'e97353')) {
          total += entry.time_diff
        }
      })

      return total ? this.secondsToTime(total) : '00:00'
    },

    /**
     * Get daily timeSheet data
     */
    async timeSheetData(id) {
      this.loader = true
      if (!this.startDate || !this.endDate) {
        return
      }
      const input = {
        is_active:
          this.isActive && this.isActive !== 'all_data' ? this.isActive === 'active' : '',
        start_date: this.startDate,
        end_date: this.endDate,
        page: this.currentPage,
        per_page: this.perPage,
        sort_field: 'name',
        sort_order: this.sortDesc ? 'desc' : 'asc',
        member: id ? [] : this.userID,
        manage_by: id ? [] : this.manageBy,
        reporting_to: id ? [] : this.reportingTo,
        group_id: id ? [] : this.groupId,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/timesheet/dailyTimeSheet',
        input,
        false,
      )
      if (response && response.status == 200) {
        this.logData = response.data
        const { data } = response
        this.items = data.users
        this.totalCount = data.count
        this.progressBarFunction()
        this.loader = false
      } else {
        this.loader = false
      }
    },

    /* progressbar  functions */
    progressBarFunction() {
      this.items.forEach((element, index) => {
        this.ManageUserTask(element.clock, index)
      })
    },

    /* Note :- need to improve */
    ManageUserTask(data, indexOfItem) {
      /*  if array have length */
      if (data && data.length) {
        // sorting array
        data.sort((a, b) => (this.totalMinutes(a.in_time) > this.totalMinutes(b.in_time) ? 1 : -1))

        /* first index in_time !== 00:00:00 */
        if (moment().startOf('day') !== moment(data[0].in_time)) {
          const in_time = moment().startOf('day').format('HH:mm:ss')
          const out_time = data[0].in_time
          const obj = this.objectFunction(in_time, out_time, 'd3d3d3')
          this.items[indexOfItem].clock.unshift(obj)
        }

        /* missing index in_time  & index - 1 out_time not same add object that in_time = index-1 & out_time =index in_time */
        data.forEach((element, index) => {
          if (index != 0 && index != data.length - 1) {
            if (element.in_time !== data[index - 1].out_time) {
              const obj = this.objectFunction(
                data[index - 1].out_time,
                data[index].in_time,
                'd3d3d3',
              )
              this.items[indexOfItem].clock.splice(index, 0, obj)
            }
          }
        })

        // temp second last index
        if (data[data.length - 2].out_time !== data[data.length - 1].in_time) {
          const obj = this.objectFunction(
            data[data.length - 2].out_time,
            data[data.length - 1].in_time,
            'd3d3d3',
          )
          this.items[indexOfItem].clock.splice(data.length - 1, 0, obj)
        }

        // last index  FOR IF WE HAVE IN TIME & NOT OUT TIME
        if (data[data.length - 1].in_time && !data[data.length - 1].out_time) {
          // let end = moment().endOf("day").format("HH:mm:ss");
          const end = moment(new Date()).format('HH:mm:ss')
          const obj = this.objectFunction(
            data[data.length - 1].in_time,
            end,
            'e97353',
          )
          this.items[indexOfItem].clock.push(obj)
        }

        // last index  FOR IF WE HAVE IN TIME & OUT TIME
        if (
          data[data.length - 1].out_time
          && data[data.length - 1].out_time
          !== moment().endOf('day').format('HH:mm:ss')
        ) {
          const end = moment().endOf('day').format('HH:mm:ss')
          const obj = this.objectFunction(
            data[data.length - 1].out_time,
            end,
            'd3d3d3',
          )
          this.items[indexOfItem].clock.push(obj)
        }
      }
    },
    /* time difference */
    totalMinutes(time) {
      const hours = time ? time.substring(0, 2) * 60 : 0
      const minutes = time ? time.substring(3, 5) : 0
      return (Number(hours) + Number(minutes)) * 60
    },

    /* common object */
    objectFunction(start, end, color) {
      const time_diff = this.totalMinutes(end) - this.totalMinutes(start)
      const obj = {
        project: {
          color,
        },
        in_time: start,
        out_time: end,
        time_diff,
      }
      return obj
    },

    userStatus(data) {
      const status = !!data.find(c => (c && c.project && c.project.color ? c.project.color == 'e97353' : ''))
      return status ? 'success' : 'secondary'
    },

    /* function for show tooltip */
    prepareToolTipValue(p) {
      // Check if 'p' and 'p.project' exist
      if (p && p.project) {
        // If project color is 'd3d3d3', return 'not worked'
        if (p.project.color === 'd3d3d3') {
          return 'not worked'
        }
        // If project color is 'e97353', return formatted 'In' time
        if (p.project.color === 'e97353') {
          return `In:${p.in_time ? p.in_time.substring(0, 5) : p.in_time}`
        }
      }
      // For other cases, return formatted 'In', 'Out', and 'Total' times
      return `In:${p.in_time ? p.in_time.substring(0, 5) : p.in_time}<br> Out:${p.out_time ? p.out_time.substring(0, 5) : p.out_time}<br> Total:${this.secondsToTime(p.time_diff)}`
    },
    /* get time label */
    getTimeLabel(n) {
      if (n === 1) return "12AM"
      if (n === 4) return "12PM"
      return n < 4 ? `${(n - 1) * 4  }AM` : `${(n - 4) * 4  }PM`
    },

  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/component-css/viewbody.css";

.custom-progress {
  color: transparent;
}

.progressbar-fix-parameter {
  float: left;
  font-size: 0.8rem;
  color: #5e5873;
  font-weight: 500;
}
</style>
